<script>
import { URL_REDIRECT } from '@dmant/ez-env-common';
import { mapState } from 'vuex';
import webinarsManager from '@/services/webinarsManager';

export default {
  name: 'ezfPreviewWebinarReplay',
  computed: {
    ...mapState('funnel', ['funnel']),
  },
  methods: {
    makeSessions(hashesList) {
      const hashes = hashesList.filter((hash) => hash.schedule[0]);
      return hashes.sort((a, b) => {
        const dA = new Date(a.datetime);
        const dB = new Date(b.datetime);

        return dA.getTime() - dB.getTime();
      });
    },
  },
  watch: {
    'funnel.webinarId': {
      async handler(webinarId) {
        if (!webinarId) {
          return;
        }
        const hashes = await webinarsManager.getWebinarsSessions(
          webinarId,
          { cond: 'eq', value: 3 },
          { cond: 'neq', value: 2 },
        );

        const allReplays = this.makeSessions(hashes);
        const nowTime = new Date().getTime();
        const [selectedReplay] = allReplays
          .filter((session) => {
            const d = new Date(session.datetime);
            return d.getTime() < nowTime;
          })
          .splice(0, 5);
        if (selectedReplay) {
          const url = `${URL_REDIRECT}/rw/${this.funnel.id}/${webinarId}/replay/${selectedReplay.hash}`;
          if (window.location.replace) {
            window.location.replace(url);
          } else {
            window.location = url;
          }
        }
      },
      immediate: true,
    },
  },
};
</script>

<template>
  <ez-preloader :value="true" :stop="false" />
</template>
